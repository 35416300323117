<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm title="Change Password"
                       in-progress-message="JDoodle is trying to change your password, Please wait..."
                       success-message="Password Successfully Changed."
                       action-text="Change Password"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="changePassword"
                       @close="$refs.sideBar.emitToggle()">
      <DoodleInput field-name="oldPassword" label-text="Old Password" type="password"
                   left-icon="lock"
                   rule="required|min:5"
                   v-model="doodleForm.oldPassword" place-holder=""/>
      <DoodleInput field-name="newPassword" label-text="New Password" type="password"
                   left-icon="lock"
                   rule="required|min:5|max:50"
                   v-model="doodleForm.newPassword" place-holder="" reff="newPassword"/>
      <DoodleInput field-name="retypeNewPassword" label-text="Retype New Password" type="password"
                   left-icon="lock"
                   :rule="{required:true, confirmed:doodleForm.newPassword}"
                   v-model="doodleForm.retypeNewPassword" place-holder=""/>
    </DoodleGeneralForm>
  </SideBar>
</template>

<script>
import formMixin from '../assets/javascript/form-mixin'
import DoodleGeneralForm from './Utils/DoodleGeneralForm'
import DoodleInput from './Utils/DoodleInput'
import SideBar from './Utils/SideBar'

export default {
  name: 'changePassword',
  components: { SideBar, DoodleInput, DoodleGeneralForm },
  inject: ['$validator'],
  mixins: [formMixin],
  data: function () {
    return {
      doodleForm: {
        oldPassword: null,
        newPassword: null,
        retypeNewPassword: null
      }
    }
  },
  methods: {
    changePassword () {
      this.executeAPI({
        url: '/api/register/changePassword',
        data: { 'oldPassword': this.doodleForm.oldPassword,
          'newPassword': this.doodleForm.newPassword,
          'retypeNewPassword': this.doodleForm.retypeNewPassword },
        method: 'post',
        successAction: null,
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GLOBAL,
        jdaEvent: 'change-password',
        jdaLabel: ''
      })
    }
  }
}
</script>
